@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --primary-color: #0066CC;
  --secondary-color: #F5F5F5;
  --text-color: #333333;
  --light-text-color: #666666;
  --background-color: #FFFFFF;
  --border-color: #E0E0E0;
} */
:root {
  /* Original brand color */
  --brand-orange: rgb(234, 163, 104);

  /* Complementary colors */
  --deep-navy: rgb(28, 54, 84);
  --teal: rgb(0, 128, 128);
  --warm-gray: rgb(128, 117, 107);
  --deep-purple: rgb(76, 40, 130);
  --forest-green: rgb(34, 87, 26);
  --charcoal: rgb(54, 69, 79);
  --cream: rgb(255, 253, 208);

  --logo-text-color: #1E1E1E;
  --logo-color:#eaa368
  #1E1E1E
  /* Other colors used */
  --darkbluegray:#1a2133;
  --darkbluegray2:#23304a;
  --darkbluegray3:#2c3e61;
  --darkbluegray4:#354d78;
  --darkbluegray5:#3e5c8f;

  /* Named aliases for semantic use */
  --primary-color: var(--brand-orange);
  --secondary-color: var(--deep-navy);
  --accent-color: var(--teal);
  --background-color: var(--cream);
  --text-color: var(--charcoal);
  --highlight-color: var(--deep-purple);
  --natural-color: var(--forest-green);
  --neutral-color: var(--warm-gray);
}




* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: 1.5;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-bottom: 1rem;
}

H3.service-card{
  margin-bottom: 0px;
}


.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #0055AA;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.btn-outline:hover {
  background-color: white;
  color: var(--primary-color);
}

.section {
  padding: 4rem 0;
}

/* Hero specific styles */
.hero {
  background-color: var(--primary-color);
  color: white;
  padding: 5rem 0;
}

.hero h1 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.hero p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.hero-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.hero-stats {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 3rem;
}

.hero-stat {
  text-align: center;
}

.hero-stat-value {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.hero-stat-label {
  font-size: 0.875rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1.125rem;
  }

  .hero-cta {
    flex-direction: column;
    align-items: center;
  }

  .hero-stats {
    flex-direction: column;
    gap: 2rem;
  }
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}


header{
  position: fixed;
  background-color: #fff;
  left: 0;
  position: sticky;
  top: 0;
  transition: background-color .3s;
  width: 100%;
  z-index: 5;
  display: block;

}
header.container{
  max-width: 1200px;
  margin: 0 auto;
  
  align-items: center;
  display: flex;
  padding-bottom: 16px;
  padding-top: 16px;
  position: relative;
  padding-right: 24px;
  padding-left: 24px;
}